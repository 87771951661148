import styled from 'styled-components';

import RichText from '../rich-text';

export const FullWidth = styled.div`
  margin-right: -75px;
  margin-left: -75px;
  margin-bottom: -75px;
  width: calc(100% + 150px);
  height: 100%;
  ${({ theme }) =>
    theme.breakpoints.under_m(`
    margin-right: -15px;
    margin-left: -15px;
    width: calc(100% + 30px);
    margin-bottom: -80px;
`)}
`;
export const StyledBackgroundImage = styled.section`
  height: 100%;
  background: ${({ theme }) => theme.color.orange.hex};
  padding-top: 150px;
  ${({ theme }) =>
    theme.breakpoints.under_m(`
    padding: 75px 15px;
`)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 95px;
  ${({ theme }) =>
    theme.breakpoints.under_m(`
    flex-direction: column;
`)}
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  text-align: center;
  color: ${({ theme }) => theme.color.white.hex};
`;
export const SubTitle = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.typo.l};
  color: ${({ theme }) => theme.color.white.hex};
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > span {
    margin: 25px 0;
  }
`;

export const Separator = styled.span<{ width?: string }>`
  width: ${({ width }) => (width ? width : '20%')};
  height: 2px;
  background-color: ${({ theme }) => theme.color.white.hex};
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 75px;
  flex-direction: column;
  color: ${({ theme }) => theme.color.white.hex};
  font-weight: normal;
  ${({ theme }) =>
    theme.breakpoints.under_m(`
    align-items: start;
    flex-direction: column;
    margin-top: 40px;
    height: 100%;
    margin-left: 0px;
`)}
  > * {
    font-weight: normal;
    margin: 0;
    text-align: left;
  }
  a {
    color: ${({ theme }) => theme.color.white.hex};
  }

  > span {
    margin: 24px 0;
    ${({ theme }) =>
      theme.breakpoints.under_m(`
    margin: 15px 0;
    `)}
  }
`;
export const StyledRichText = styled(RichText)`
  p {
    font-size: ${({ theme }) => theme.typo.l};
    color: ${({ theme }) => theme.color.white.hex};
    font-weight: normal;
    margin: 0;
  }
`;
