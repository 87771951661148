import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { IRichText } from '../../pages';
import { WhiteLink } from '../buttons';
import { Title, SubTitle, Details, StyledRichText, Content, Buttons, Separator, FullWidth, StyledBackgroundImage } from './style';

export interface IContactProps {
  email: string;
  phone: string;
  className?: string;
  address: IRichText;
}

export const Contact: React.FC<IContactProps> = ({ email, phone, address, className }) => {
  const theme = useTheme();
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "CONTACT_2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `,
  );
  const imageData = background.childImageSharp.fluid;
  console.log(imageData);
  return (
    <FullWidth>
      <StyledBackgroundImage className={className}>
        <Title>Vous souhaitez parler de votre projet ?</Title>
        <SubTitle>Contactez-moi par mail ou demander un devis sur-mesure !</SubTitle>
        <Content>
          <Buttons>
            <WhiteLink href={`/devis`} width="200px">
              Devis
            </WhiteLink>
            <Separator />
            <WhiteLink href={`mailto:${email}?subject=Demande de devis`} width="200px">
              Envoyez un mail
            </WhiteLink>
          </Buttons>
          <Details itemScope itemType="https://schema.org/Business">
            <h3 itemProp="name">Lou-Anne Dupont</h3>
            <Separator width="20px" />
            <StyledRichText richText={address} itemProp="address" />
            <Separator width="20px" />
            <h3>
              <a href={`mailto:${email}?subject=Prise de contact`} itemProp="email">
                {email}
              </a>
            </h3>
            <h3>
              <a href={`tel:${phone}`} itemProp="telephone">
                {phone}
              </a>
            </h3>
          </Details>
        </Content>
      </StyledBackgroundImage>
    </FullWidth>
  );
};

export const StyledContact = styled(Contact)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`;

export default StyledContact;
