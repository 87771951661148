import React from 'react';
import { graphql } from 'gatsby';
import { IBioImageContact } from '.';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contact from '../components/contact';
import { FluidObject } from 'gatsby-image';

interface IContactProps {
  data: {
    allContentfulBioImageContact: {
      edges: Array<{ node: IBioImageContact }>;
    };
    background: {
      childImageSharp: {
        fluid: FluidObject;
      };
    } | null;
  };
  location: Location;
}
const ContactPage: React.FC<IContactProps> = ({ data }) => {
  const [meta] = data.allContentfulBioImageContact.edges;
  return (
    <Layout instagramUrl={meta.node.instagram}>
      <SEO title={'Contact'} />
      <Contact email={meta.node.email} address={meta.node.address} phone={meta.node.phone} />
    </Layout>
  );
};
export default ContactPage;

export const pageQuery = graphql`
  query ContactQuery {
    allContentfulBioImageContact(filter: { contentful_id: { eq: "2nH2prijQalfGjAOnIQfbt" } }) {
      edges {
        node {
          instagram
          email
          phone
          address {
            raw
          }
        }
      }
    }
  }
`;
